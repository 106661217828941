import { IMAGEFLUX_BASE_URL_MAP } from '@shared/constants/imagefluxBaseUrlMap';
import type { AppEnv } from '@shared/types/APP_ENV';

type createArticleImageUrlOptions = {
  /**
   * @description S3上の画像パス
   * @example DBデータそのままであれば `ui/contents/100/article/1234.jpg` のような形で渡ってくる
   */
  path: string;
  /** @description 画像の横幅 */
  w?: number;
  /** @description 画像の縦幅 */
  h?: number;
};

export function useImageUrl() {
  const APP_ENV = useRuntimeConfig().public.APP_ENV as AppEnv;

  const imageFluxBaseUrl = (() => {
    switch (APP_ENV) {
      case 'production':
        return IMAGEFLUX_BASE_URL_MAP.production;
      case 'staging':
        return IMAGEFLUX_BASE_URL_MAP.staging;
      default:
        return IMAGEFLUX_BASE_URL_MAP.local;
    }
  })();

  function createArticleImageUrl({ w, h, path }: createArticleImageUrlOptions) {
    // ref: https://console.imageflux.jp/docs/image/conversion-parameters
    const paramsObj = {
      a: 2,
      w,
      h,
    };

    // NOTE: Objectを `w=100,h=100,foo=bar,...` のようなの文字列に変換する
    const params = (Object.keys(paramsObj) as (keyof typeof paramsObj)[])
      .filter((key) => paramsObj[key] !== undefined)
      .map((key) => {
        return `${key}=${paramsObj[key]}`;
      })
      .join(',');

    return `${imageFluxBaseUrl}/${params}/${path}`;
  }

  return {
    createArticleImageUrl,
  };
}
